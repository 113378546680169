<template>
  <v-row>
    <v-col cols="12">
      <v-alert type="warning" text outlined>
        Wajib untuk klik simpan setelah mengisi data pengaturan
      </v-alert>
    </v-col>
    <!-- Tampilkan pembahasan -->
    <v-col cols="12" class="pt-0">
      <div class="font-weight-medium mb-2">
        <span> Tampilkan Pembahasan </span>
      </div>
      <v-radio-group v-model.number="data.is_show_discussion" @change="changeShowDiscussion(data)">
        <v-radio :value="1">
          <span slot="label" class="color--text">Ya</span>
        </v-radio>
        <v-radio :value="0">
          <span slot="label" class="color--text">Tidak</span>
        </v-radio>
      </v-radio-group>
    </v-col>
    <!-- Konfigurasi nilai minimal -->
    <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span> Atur Nilai Minimal </span>
      </div>
      <v-radio-group v-model="set_minimum_value.is_set">
        <v-radio :value="1">
          <span slot="label" class="color--text">Ya</span>
        </v-radio>
        <v-radio :value="0">
          <span slot="label" class="color--text">Tidak</span>
        </v-radio>
      </v-radio-group>
    </v-col>
    <!-- Nilai minimal -->
    <v-expand-transition>
      <v-col v-if="set_minimum_value.is_set != '0'" cols="12">
        <div class="font-weight-medium mb-2">
          <span> Nilai Minimal </span>
        </div>
        <v-text-field
          v-model="set_minimum_value.minimum_value"
          placeholder="Masukkan nilai minimal"
        ></v-text-field>
      </v-col>
    </v-expand-transition>
    <!--  <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span> Atur Penilaian Otomatis </span>
      </div>
      <v-radio-group>
        <v-radio>
          <span slot="label" class="color--text">Ya</span>
        </v-radio>
        <v-radio>
          <span slot="label" class="color--text">Tidak</span>
        </v-radio>
      </v-radio-group>
    </v-col> -->
    <!-- Mengulang -->
    <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span> Kesempatan Mengulang </span>
      </div>
      <v-select
        v-model.number="data.opportunity_repeat_test"
        :items="tries"
        item-value="val"
        placeholder="Pilih kesempatan mengulang"
      ></v-select>
    </v-col>
    <!-- Skip -->
    <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span> Skip </span>
      </div>
      <v-radio-group v-model.number="data.is_skip">
        <v-radio :value="1">
          <span slot="label" class="color--text">Ya</span>
        </v-radio>
        <v-radio :value="0">
          <span slot="label" class="color--text">Tidak</span>
        </v-radio>
      </v-radio-group>
    </v-col>
    <!-- Tampilkan timer -->
     <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span> Tampilkan Timer </span>
      </div>
      <v-radio-group v-model.number="data.is_show_timer">
        <v-radio :value="1">
          <span slot="label" class="color--text">Ya</span>
        </v-radio>
        <v-radio :value="0">
          <span slot="label" class="color--text">Tidak</span>
        </v-radio>
      </v-radio-group>
    </v-col>
    <!-- Tampilkan score -->
    <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span> Tampilkan Score </span>
      </div>
      <v-radio-group v-model.number="data.is_show_score">
        <v-radio :value="1">
          <span slot="label" class="color--text">Ya</span>
        </v-radio>
        <v-radio :value="0">
          <span slot="label" class="color--text">Tidak</span>
        </v-radio>
      </v-radio-group>
    </v-col>
    <!-- Tampilkan camera -->
    <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span> Tampilkan Camera </span>
      </div>
      <v-radio-group v-model.number="data.is_camera_active">
        <v-radio :value="1">
          <span slot="label" class="color--text">Ya</span>
        </v-radio>
        <v-radio :value="0">
          <span slot="label" class="color--text">Tidak</span>
        </v-radio>
      </v-radio-group>
    </v-col>
    <!-- Konfig acak urutan soal -->
    <v-col cols="12">
      <v-checkbox v-model.number="data.is_random_question" :true-value="1" :false-value="0">
        <span slot="label" class="color--text">Acak urutan soal / grup soal</span>
      </v-checkbox>
    </v-col>
    <!-- Jumlah soal -->
    <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span> Jumlah Soal per Paket </span>
      </div>
      <v-text-field
        v-model.number="data.number_questions_package"
        type="number"
        placeholder="Masukkan jumlah soal per paket"
      ></v-text-field>
    </v-col>
    <!-- Konfig acak soal per paket -->
    <!-- <v-col cols="12">
      <v-checkbox>
        <span slot="label" class="color--text">Acak soal per paket</span>
      </v-checkbox>
    </v-col> -->
    <!-- Estianti -->
    <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span> Estimasi Durasi (Menit) </span>
      </div>
      <v-row>
        <v-col cols="12" md="6" class="py-0">
          <v-row>
            <v-col class="pt-0">
              <v-text-field
                v-model="data.estimated_duration"
                type="number"
                min="0"
                placeholder="00"
              ></v-text-field>
              <!-- <div class="text--secondary mt-2">Jam</div> -->
            </v-col>
            <!-- <v-col class="pt-0">
              <v-text-field type="number" min="0" max="59" placeholder="00"></v-text-field>
              <div class="text--secondary mt-2">Menit</div>
            </v-col>
            <v-col class="pt-0">
              <v-text-field type="number" min="0" max="59" placeholder="00"></v-text-field>
              <div class="text--secondary mt-2">Detik</div>
            </v-col> -->
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span> Waktu Ujian (Menit) </span>
      </div>
      <v-row>
        <v-col cols="12" md="6" class="py-0">
          <v-row>
            <v-col class="pt-0">
              <v-text-field
                v-model="data.timer"
                type="number"
                min="0"
                placeholder="00"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <!-- Boboboy -->
    <!-- <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span> Bobot Assesmen </span>
      </div>
      <v-text-field placeholder="Masukkan bobot assesmen"></v-text-field>
    </v-col>
               -->
  </v-row>
</template>
<script>
import model from "../_mixins/model";

export default {
  mixins: [model],
  data() {
    return {
      nilaiOpsi: null,
      nilai: "",
      set_minimum_value: {},
    };
  },
  computed: {
    tries() {
      return [
        { val: 10, text: "10 x" },
        { val: 50, text: "50 x" },
        { val: 100, text: "100 x" },
        { val: 0, text: "Tanpa mengulang" },
        { val: -1, text: "Mengulang tanpa batas" },
      ];
    },
    // set_minimum_value() {
    //   return this.data?.set_minimum_value || {};
    // },
  },
  watch: {
    set_minimum_value: {
      handler(val) {
        this.$set(this.data, "set_minimum_value", val);
        // this.data.set_minimum_value = val;
      },
      deep: true,
    },
  },
  created() {
    this.$set(this.data, "is_random_question", this.data.is_random_question);
    this.$set(this.set_minimum_value, "is_set", this.data.set_minimum_value?.is_set);
    this.$set(this.set_minimum_value, "minimum_value", this.data.set_minimum_value?.minimum_value);
  },
  methods: {
    changeShowDiscussion(data) {
      if (data.is_show_discussion === 1 || data.is_show_discussion === '1' || data.is_show_discussion === true) {
        this.$set(this.data, "is_show_answer", '');
      } else {
        this.$set(this.data, "is_show_answer", 0);
      }
    },
  },
};
</script>
<style>
#ck .v-label {
  color: #4c6583;
}
#ck .v-input--checkbox .v-icon {
  color: #4c6583;
}
</style>
