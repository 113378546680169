<template>
  <v-col cols="12" v-bind="$attrs" v-on="$listeners">
    <v-card outlined>
      <v-row v-if="loading" class="py-4 px-8">
        <v-col cols="12" class="text-center">
          <v-progress-circular indeterminate color="primary" />
        </v-col>
      </v-row>
      <template v-else>
        <v-row class="py-4 px-8">
          <v-col>
            <v-tabs v-model="tab">
              <v-tab class="pa-1 mr-2 text-capitalize" v-for="tab in tabs" :key="tab">
                <span>
                  {{ tab }}
                </span>
              </v-tab>
            </v-tabs>
            <div class="pt-8">
              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <div class="font-weight-bold">Aktivitas {{ seq }}</div>
                  <v-row>
                    <v-col cols="12">
                      <div class="font-weight-medium mb-2">Judul Aktivitas</div>
                      <v-text-field
                        outlined
                        placeholder="Masukkan judul aktivitas"
                        v-model="activity.title"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <div class="font-weight-medium">Jenis Aktivitas</div>
                      <v-select
                        :disabled="$route.name == 'activity-edit'"
                        :items="types"
                        item-text="text"
                        item-value="value"
                        v-model="activity.type"
                      ></v-select>
                    </v-col>
                    <v-col v-if="isAbleToClone" cols="12">
                      <v-checkbox v-model="activity.is_clone">
                        <span slot="label" class="color--text">Clone pre test</span>
                      </v-checkbox>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item>
                  <setting-pre v-if="QuestionSetting" v-model="activity.settings" />
                  <setting-quiz v-if="Question2Setting" v-model="activity.settings" />
                  <setting-post v-if="Question3Setting" v-model="activity.settings" />
                  <setting-video v-if="VideoSetting" v-model="activity.settings" />
                  <setting-diskusi v-if="DiskusiSetting" v-model="activity.settings" />
                  <setting-practice v-if="PracticeSetting" v-model="activity.settings" />
                  <setting-evaluation v-if="EvaluationSetting" v-model="activity.settings" />
                  <setting-book v-if="BookSetting" v-model="activity.settings" />
                  <setting-eprak v-if="EprakSetting" v-model="activity.settings" />
                </v-tab-item>
                <v-tab-item>
                  <content-pre v-if="QuestionSetting" ref="question" v-model="activity.theory" />
                  <content-quiz v-if="Question2Setting" ref="question" v-model="activity.theory" />
                  <content-post v-if="Question3Setting" ref="question" v-model="activity.theory" />
                  <content-video v-if="VideoSetting" ref="resources" v-model="activity.theory" />
                  <content-practice
                    v-if="PracticeSetting"
                    ref="resources"
                    v-model="activity.theory"
                  />
                  <content-book v-if="BookSetting" ref="resources" v-model="activity.theory" />
                  <content-diskusi v-if="DiskusiSetting" v-model="activity.theory" />
                  <content-evaluation v-if="EvaluationSetting" v-model="activity.theory" />
                  <content-eprak v-if="EprakSetting" v-model="activity.theory" />
                </v-tab-item>
              </v-tabs-items>
            </div>
          </v-col>
        </v-row>
        <v-progress-linear v-if="submiting" :value="progress"></v-progress-linear>
        <v-card-actions class="background">
          <v-spacer></v-spacer>
          <v-btn color="primary" :disabled="submiting" outlined @click="$emit('click:cancel')"
            >Batal</v-btn
          >
          <v-btn color="primary" :loading="submiting" @click="handleSubmit">Simpan</v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-col>
</template>

<script>
import SettingPractice from "./Settings/Video";
import SettingBook from "./Settings/Video";
import SettingPre from "./Settings/Question";
import SettingQuiz from "./Settings/Question";
import SettingPost from "./Settings/Question";
import SettingVideo from "./Settings/Video";
import SettingDiskusi from "./Settings/Video";
import SettingEvaluation from "./Settings/Video";
import SettingEprak from "./Settings/Video";
import ContentPre from "./Contents/Question";
import ContentQuiz from "./Contents/Question";
import ContentPost from "./Contents/Question";
import ContentVideo from "./Contents/Video";
import ContentEvaluation from "./Contents/Evaluation";
import ContentDiskusi from "./Contents/Disscus";
import ContentPractice from "./Contents/Practice";
import ContentBook from "./Contents/Practice";
import ContentEprak from "./Contents/Evaluation";

export default {
  components: {
    SettingPre,
    SettingPost,
    SettingQuiz,
    SettingVideo,
    SettingPractice,
    SettingBook,
    SettingEvaluation,
    SettingEprak,
    SettingDiskusi,

    ContentPost,
    ContentPre,
    ContentQuiz,
    ContentVideo,
    ContentEvaluation,
    ContentDiskusi,
    ContentPractice,
    ContentBook,
    ContentEprak,
  },
  props: {
    activity: { type: Object, required: true },
    seq: { type: [Number, String], required: true },
    submiting: Boolean,
  },
  data: () => ({
    tab: null,
    loading: null,
    clone: {},
    progress: null,
  }),
  computed: {
    tabs() {
      const tab =
        this.QuestionSetting || this.Question2Setting || this.Question3Setting
          ? "Pertanyaan"
          : // : this.VideoSetting || this.PracticeSetting ||
            // ? "Konten"
            "Konten";
      return ["Detail", "Pengaturan", tab];
    },
    types() {
      return [
        { value: "menonton_video", text: "Menonton Video" },
        { value: "pre_test", text: "Pre Test" },
        { value: "quiz", text: "Quiz" },
        { value: "post_test", text: "Post Test" },
        { value: "praktek_mandiri", text: "Praktek Mandiri" },
        { value: "evaluasi_praktek_mandiri", text: "Evaluasi Praktek Mandiri" },
        { value: "membaca-e-book", text: "Membaca E-Book" },
        { value: "berdiskusi_daring", text: "Berdiskusi Daring" },
        { value: "evaluasi", text: "Evaluasi" },
      ];
    },
    isAbleToClone() {
      return (
        this.$route.name == "activity-create" &&
        ["post_test"].includes(this.activity.type) &&
        this.clone.type == "pre_test"
      );
    },
    VideoSetting() {
      return this.activity.type == this.types[0].value;
    },
    QuestionSetting() {
      return this.activity.type == this.types[1].value;
    },
    Question2Setting() {
      return this.activity.type == this.types[2].value;
    },
    Question3Setting() {
      return this.activity.type == this.types[3].value;
    },
    PracticeSetting() {
      return this.activity.type == this.types[4].value;
    },
    EprakSetting() {
      return this.activity.type == this.types[5].value;
    },
    BookSetting() {
      return this.activity.type == this.types[6].value;
    },
    DiskusiSetting() {
      return this.activity.type == this.types[7].value;
    },
    EvaluationSetting() {
      return this.activity.type == this.types[8].value;
    },
  },
  watch: {
    activity: {
      handler() {
        this.$emit("update:activity", this.activity);
      },
      deep: true,
    },
  },
  methods: {
    async handleSubmit() {
      if (this.$refs.question) {
        await this.$refs.question
          .saveQuestions()
          .then(() => {
            this.$emit("click:submit", this.activity);
          })
          .catch((error) => {
            console.error(error);
            this.$emit("click:submit", error);
          });
      } else {
        this.$emit("click:submit", this.activity);
      }
    },
    async handleUpload(progress) {
      this.progress = progress.loaded/progress.total * 99;
    },
    normalizeSize(byte) {
      let units = "";
      if (byte / 1024 > 0.2) {
        byte = (byte / 1024).toFixed(2);
        units = "KB";
      }
      if (byte / 1024 > 0.2) {
        byte = (byte / 1024).toFixed(2);
        units = "MB";
      }
      if (byte / 1024 > 0.2) {
        byte = (byte / 1024).toFixed(2);
        units = "GB";
      }
      return `${byte}${units}`;
    },
  },
  async created() {
    this.loading = true;
    const cloneData = await this.axios
      .get(`/program/v1/company/check-available-prepost/${this.$route.params.program}`)
      .then((res) => res.data);
    this.clone = cloneData.data;
    if (["quiz", "pre_test", "post_test"].includes(this.activity.type)) {
      await this.$store.dispatch("packet/get", {
        program: this.$route.params.program,
        topic: this.$route.params.topic,
        activity: this.$route.params.id,
        params: { order_by: "created_at", dir: "asc" },
      });
    }
    this.loading = false;
  },
};
</script>
