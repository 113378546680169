var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"transparent px-2",attrs:{"elevation":"0"}},[_c('vue-html2pdf',{ref:"certifPeserta",attrs:{"enable-download":true,"preview-modal":false,"show-layout":true,"pdf-quality":2,"filename":"sertifikat","pdf-orientation":"landscape","manual-pagination":true,"pdf-content-width":"100%"},on:{"hasDownloaded":function($event){return _vm.hasGenerated($event)}}},[_c('section',{attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('section',{staticClass:"pdf-item",style:({
          backgroundImage: ("url('" + (require('@/assets/images/bg-sertif.jpg')) + "')"),
          backgroundSize: "100% 100%",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "790px",
          color: "#4B4B4B",
        })},[_c('div',{class:{style1: this.competence.length != 0}},[_c('div',{staticStyle:{"text-align":"center","margin-bottom":"15px"}},[_c('img',{attrs:{"src":require("@/assets/images/logo-aditif.png"),"alt":"","srcset":""}})]),_c('div',{staticStyle:{"font-family":"Roboto","font-style":"normal","font-weight":"bold","font-size":"42px","line-height":"47px","text-align":"center","letter-spacing":"0.015em","margin-top":"10px","margin-bottom":"15px"}},[_vm._v(" Sertifikat Penyelesaian ")]),_c('div',{staticStyle:{"font-family":"Roboto","font-weight":"normal","font-size":"16px","line-height":"20px","text-align":"center","margin-top":"4px"}},[_vm._v(" Diberikan atas keberhasilan mengikuti dan lulus dari "),_c('br'),_vm._v(" Kelas "+_vm._s(_vm.program)+" ")]),_c('div',{staticStyle:{"font-family":"Roboto","font-style":"normal","font-weight":"900","font-size":"24px","line-height":"27px","text-align":"center","margin-top":"50px"}},[_vm._v(" "+_vm._s(_vm.member.fullname)+" ")]),_c('hr',{staticStyle:{"width":"35%","border":"2px solid #f59e1c","margin":"auto"}}),_c('div',{staticStyle:{"display":"flex","margin-top":"40px"}},[_c('div',{staticStyle:{"width":"30%"}},[_c('div',{staticStyle:{"font-family":"Roboto","font-style":"normal","font-weight":"400","font-size":"13px","line-height":"16px","text-align":"left","/*margin-top":"20px","*/\n                  /*margin-left":"20px","*/\n                  position":"absolute","margin-top":"25%","margin-left":"10%"}})]),_c('div',{staticStyle:{"width":"40%"}},[_c('div',{staticStyle:{"font-family":"Roboto","font-style":"normal","font-weight":"bold","font-size":"16px","line-height":"16px","text-align":"center","margin-bottom":"35px","margin-top":"30px","color":"#898786"}},[_vm._v(" Yogyakarta, "+_vm._s(_vm.currentDateTime(_vm.data.end_test_at))+" ")])]),_c('div',{staticStyle:{"width":"30%"}})])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }